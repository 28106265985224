import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUs/About Us';
import OurMisson from './components/Our Mission/Our Mission';
import Contact from './components/ContactUs/contact';
import Constuction from './components/Services/What We Do/Services';
import Consulting from './components/Services/Consulting/consulting';
import Projects from './components/Projects/Projects';

function App() {
  return (
    <Routes>
      <Route path="/" element={<div className="route-container"><Home /></div>} />
      <Route path="/about-us" element={<div className="route-container"><AboutUs /></div>} />
      <Route path="/our-mission" element={<div className="route-container"><OurMisson /></div>} />
      <Route path="/contact" element={<div className="route-container"><Contact /></div>} />
      <Route path="/services" element={<div className="route-container"><Constuction /></div>} />
      <Route path="/consulting" element={<div className="route-container"><Consulting /></div>} />
      <Route path="/projects" element={<div className="route-container"><Projects /></div>} />

      {/* Login Signup Route */}
    </Routes>
  );
}

export default App;
