import { Link } from 'react-router-dom';
import React from 'react';
import BackgroundVideo from '../../Assets/Video/2073129-uhd_3840_2160_30fps.mp4'; // Replace with your video file

const Hero = () => {
  return (
    <section className="relative w-full h-screen overflow-hidden bg-[#0B1A2B]">
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 object-cover w-full h-full pointer-events-none"
        src={BackgroundVideo}
        autoPlay
        loop
        muted
        style={{
            filter: 'brightness(0.5)', // Increase value for lighter video
            width: '100%', // Adjust width as needed
            height: 'auto', // Maintain aspect ratio
          }}
      />
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      {/* Hero Content */}
      <div className="relative z-10 flex flex-col items-center justify-center w-full h-full text-center text-white">
  <h1 className="text-4xl font-bold sm:text-5xl lg:text-5xl text-[#35AC9F]">
    Our Valuable Services
  </h1>
  <p className="mt-4 text-lg sm:text-xl font-serif lg:text-1xl text-[#A97C50]">
    General Contacting Services, Skill & unskilled manpower supply services, Heavy equipments supply services, Scrape Handling
  </p>
</div>

    </section>
  );
};

export default Hero;
