import { Link } from 'react-router-dom';
import React from 'react'; 

const AboutUs = () => {
  return (
    <div className="bg-white text-gray-800">

      {/* Vision & Values Section */}
      <section className="px-8 py-16 md:px-16 lg:px-32 text-center">
        <h2 className="text-4xl font-bold text-[#A97C50] mb-6">Our Vision & Values</h2>
        <p className="text-lg leading-relaxed mb-12">
          At Pecific Coast Solutions Contracting, we believe in pushing the boundaries of excellence. Our commitment to quality,
          innovation, and safety makes us a leader in the industry, providing unparalleled support for our clients.
        </p>
      </section>

      {/* Team Highlights Section */}
      <section className="px-8 py-16 md:px-16 lg:px-32 bg-gray-100 text-center">
        <h2 className="text-4xl font-bold text-[#35AC9F] mb-6">Meet Our Team</h2>
        <div className="flex flex-wrap justify-center gap-8">
          {[
            { name: "MR Parvaiz Iqbal", role: "CEO", image: "path_to_team_image_1.jpg" },
            { name: "MR Muhammad Sohail Gondal", role: "General Manager", image: "path_to_team_image_2.jpg" },
            { name: "MR Hubdar Hussain", role: "Operation Manager", image: "path_to_team_image_3.jpg" },
          ].map((member, index) => (
            <div 
              key={index} 
              className="w-72 p-6 bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-105 animate-fade-in"
            >
              <img 
                src={member.image} 
                alt={member.name} 
                className="w-full h-40 rounded-md mb-4" 
              />
              <h3 className="text-xl font-semibold">{member.name}</h3>
              <p className="text-gray-600">{member.role}</p>
            </div>
          ))}
        </div>

        <section className="bg-gray-100 py-16 px-8 lg:px-32 text-center">
          <h2 className="text-4xl font-bold text-[#35AC9F] mb-8" style={{ fontFamily: "'Poppins', sans-serif" }}>
            CEO Message
          </h2>
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl mx-auto text-left">
            <p className="text-lg text-[#A97C50] mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
              From the day of its establishment in 2021, Pacific Coast Solutions (PCS) is proud of great numbers of successfully completed projects.
              Over the past few years, PCS has succeeded to complete several projects in the Kingdom of Saudi Arabia.
            </p>
            <p className="text-lg text-[#A97C50] mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
              Our target is to enlarge supplied services including skilled and unskilled manpower supply, heavy equipment rentals, general contracting
              and trading, and industrial scrap buying and selling.
            </p>
            <p className="text-lg text-[#A97C50] mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
              Our struggling and studies are going on for this purpose. And we are focusing to accomplish greater projects than the previously gained experiences.
            </p>
            <p className="text-lg text-[#A97C50] mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
              I say proudly that we are found as a preferred and competitive company by our clients, and the responsibility of this situation is so important
              for our company. Along with our experienced staff, we are encouraged to be more successful in the coming years. One of the main reasons for our
              success is the hardworking employees at our head office and self-denying site staff.
            </p>
            <p className="text-lg text-[#A97C50] font-bold text-right mt-8" style={{ fontFamily: "'Poppins', sans-serif" }}>
              Thanks,<br />
              CEO, Pacific Coast Solutions
            </p>
          </div>
        </section>
      </section>


      {/* MR Parvaiz Iqbal CEO, MR Muhammad Sohail Gondal General Manager, MR Hubdar Hussain Operation Manager */}

      {/* Why Choose Us Section */}
<section className="px-8 py-16 md:px-16 lg:px-32 text-center">
  <h2 className="text-4xl font-bold text-[#A97C50] mb-6">Why Choose Us</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    {[
      {
        title: 'Safety First',
        description: 'We prioritize the safety of our team and clients above all.',
        bgColor: '#35AC9F',
        textColor: '#fff',
      },
      {
        title: 'Quality Assurance',
        description: 'Our commitment to quality ensures top-notch results.',
        bgColor: '#A97C50',
        textColor: '#fff',
      },
      {
        title: 'Innovative Solutions',
        description: 'We implement the latest technologies for efficient project delivery.',
        bgColor: '#A97C50',
        textColor: '#fff',
      },
      {
        title: 'Client-Centric Approach',
        description: 'We listen to our clients to tailor solutions to their needs.',
        bgColor: '#35AC9F',
        textColor: '#fff',
      },
    ].map((item, index) => (
      <div
        key={index}
        className="relative w-full h-64 transform transition-transform duration-300 hover:shadow-xl hover:rotate-1"
      >
        <div
          className="absolute inset-0 bg-white rounded-lg flex items-center justify-center p-6"
          style={{ backgroundColor: item.bgColor }}
        >
          <h3 className="text-2xl font-semibold" style={{ color: item.textColor }}>
            {item.title}
          </h3>
        </div>
        <div
          className="absolute inset-0 bg-gray-100 rounded-lg flex items-center justify-center p-6 transform rotate-y-180"
          style={{ backgroundColor: item.bgColor }}
        >
          <p className="text-lg text-center text-gray-800">{item.description}</p>
        </div>
      </div>
    ))}
  </div>
</section>


      {/* Call to Action */}
      <section className="px-8 py-16 md:px-16 lg:px-32 text-center">
        {/* <button className="px-8 py-4 text-lg font-semibold text-white bg-[#35AC9F] rounded-lg shadow-md border-2 border-[#A97C50] hover:bg-[#A97C50] transition duration-300">
          Contact Us
        </button> */}
        <Link to="/contact" className="px-8 py-4 text-lg font-semibold text-white bg-[#35AC9F] rounded-lg shadow-md border-2 border-[#A97C50] hover:bg-[#A97C50] transition duration-300">
        Contact Us</Link>
      </section>

    </div>
  );
};

export default AboutUs;
