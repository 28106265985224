// PolicyPage.jsx
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './Policy.css';
import Safety from '../Assets/Policy/Safety.jpg';
import Quality from '../Assets/Policy/Quality.jpg';
import Environment from '../Assets/Policy/Envirnment.jpg';
import Innovation from '../Assets/Policy/Innovation.png';

const policies = [
  {
    id: 1,
    title: "Safety Policy",
    description: "Ensuring the highest level of safety in all operations and maintaining a safe environment.",
    image: Safety,
    bgColor: "#A97C50",
  },
  {
    id: 2,
    title: "Quality Policy",
    description: "Dedicated to delivering quality services that meet and exceed expectations.",
    image: Quality,
    bgColor: "#1D9E8F",
  },
  {
    id: 3,
    title: "Environment Policy",
    description: "Committed to sustainable practices that protect the environment.",
    image: Environment,
    bgColor: "#A97C50",
  },
  {
    id: 4,
    title: "Innovation Policy",
    description: "Encouraging innovation to drive progress and add value.",
    image: Innovation,
    bgColor: "#1D9E8F",
  },
];

const PolicyPage = () => {
  const [activePolicy, setActivePolicy] = useState(policies[0].id);

  return (
    <section className="policy-page">
      {policies.map((policy) => (
        <PolicySection
          key={policy.id}
          id={policy.id}
          title={policy.title}
          description={policy.description}
          image={policy.image}
          bgColor={policy.bgColor}
          isActive={activePolicy === policy.id}
          setActivePolicy={setActivePolicy}
        />
      ))}
    </section>
  );
};

const PolicySection = ({ id, title, description, image, bgColor, isActive, setActivePolicy }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  if (inView && !isActive) {
    setActivePolicy(id);
  }

  return (
    <div
      ref={ref}
      className={`policy-section ${isActive ? 'active' : 'hidden'}`}
      style={{
        backgroundColor: bgColor,
        minHeight: '500px', // Set consistent min-height for all sections
        height: '500px', // Uniform height
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        transition: 'opacity 0.6s ease',
        opacity: isActive ? 1 : 0,
      }}
    >
      <div className="policy-content" style={{ fontFamily: "'Poppins', sans-serif", width: '50%' }}>
        <h2 className="policy-title text-white text-2xl font-bold mb-4">{title}</h2>
        <p className="policy-description text-white mb-6">{description}</p>
      </div>
      <div className="policy-image" style={{ width: '50%', height: '100%' }}>
        <img src={image} alt={`${title}`} className="rounded-lg shadow-lg w-full h-full object-cover" />
      </div>
    </div>
  );
};

export default PolicyPage;
