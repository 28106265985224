import ImageOne1 from '../../Assets/Main Images/first_one.jpg';
import ImageOne2 from '../../Assets/Main Images/skill2.jpg';
import ImageOne3 from '../../Assets/Main Images/skill3.jpg';
import SecondImg1 from '../../Assets/Main Images/general1.jpg';
import SecondImg2 from '../../Assets/Main Images/general2.jpg';
import SecondImg3 from '../../Assets/Main Images/general3.jpg';
import ThirdImg1 from '../../Assets/Main Images/scrape1.jpg';
import ThirdImg2 from '../../Assets/Main Images/scrape2.jpg';
import forth1 from '../../Assets/Main Images/heavy2.jpg';
import forth2 from '../../Assets/Main Images/Footer.jpg';
import forth3 from '../../Assets/Main Images/largeslide.jpg';

const content_services = [
  {
    images: [SecondImg1, SecondImg2, SecondImg3],
    title: 'General Contracting & Trading',
    description: 'Ensuring quality and reliability in every aspect of our projects, we provide hand and power tools and other industrial material.',
  },
  {
    images: [ImageOne1, ImageOne2, ImageOne3],
    title: 'Skilled & Unskilled Man Power',
    description: 'Providing qualified, experienced manpower including skilled workers and staff.',
  },
  {
    images: [ThirdImg1, ThirdImg2],
    title: 'Scrap Handling',
    description: 'Innovating with cutting-edge technology solutions.',
  },
  {
    images: [forth1, forth2, forth3],
    title: 'Industrial Heavy Equipment and Tools',
    description: 'We provide all types of industrial heavy equipment, ensuring reliability and unrestricted operations.',
  },
];

export default content_services;
