// ProjectCard.js

import React, { useState } from 'react';

const ProjectCard = ({ title, summary, details, images }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => setIsOpen(!isOpen);

  return (
    <div className="project-card border rounded-lg shadow-lg p-6 bg-white transition-transform transform hover:scale-105">
      {/* Main Image */}
      <img src={images[0]} alt={title} className="w-full h-48 object-cover rounded-t-lg" />
      
      <h3 className="text-2xl font-semibold mt-4">{title}</h3>
      <p className="text-gray-600 mt-2">{summary}</p>
      
      <button 
        onClick={toggleDetails} 
        className="mt-4 px-4 py-2 bg-[#35AC9F] text-white rounded hover:bg-[#A97C50] transition"
      >
        {isOpen ? 'Hide Details' : 'View Project'}
      </button>
      
      {/* Reveal Details */}
      {isOpen && (
        <div className="mt-4 text-gray-700">
          <p>{details}</p>
          
          {/* Additional Images */}
          <div className="grid grid-cols-2 gap-4 mt-4">
            {images.slice(1).map((img, index) => (
              <img 
                key={index} 
                src={img} 
                alt={`${title} ${index + 2}`} 
                className="w-full h-32 object-cover rounded-md"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
