// src/pages/Home.js

import React from 'react';
import Nav from '../../Nav/Nav';
import Hero from '../../Hero/Hero';
import content_services from './content'; // Keep the original services content
import content_consulting from './content'; // Import the new consulting content
import Footer from '../../Footer/Footer';

function Home() {
  return (
    <div>
      <Nav />
      <Hero />

      {/* Services Section */}
      <section className="services bg-gray-100 px-8 py-16 text-gray-800">
        <h2 className="text-4xl font-bold text-center text-[#A97C50] mb-12">What We Do</h2>

        {/* Loop through services */}
        {content_services.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center mb-16 ${
              index % 2 === 1 ? 'md:flex-row-reverse' : ''
            }`} // Alternate the layout based on index
          >
            {/* Image */}
            <img
              src={service.img}
              alt={service.title}
              className="w-full md:w-1/2 rounded-lg mb-6 md:mb-0 md:mr-6 shadow-lg"
            />

            {/* Text */}
            <div className="text-left md:w-1/2">
              <h3 className="text-3xl font-semibold text-[#35AC9F] mb-4">{service.title}</h3>
              <p className="text-lg leading-relaxed">{service.description}</p>
            </div>
          </div>
        ))}
      </section>

      {/* Consulting Services Section */}
      <section className="consulting bg-gray-100 px-8 py-16 text-gray-800">
        <h2 className="text-4xl font-bold text-center text-[#A97C50] mb-12">Consulting Services</h2>

        {/* Loop through consulting services */}
        {content_consulting.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center mb-16 ${
              index % 2 === 1 ? 'md:flex-row-reverse' : ''
            }`} // Alternate the layout based on index
          >
            {/* Image */}
            <img
              src={service.img}
              alt={service.title}
              className="w-full md:w-1/2 rounded-lg mb-6 md:mb-0 md:mr-6 shadow-lg"
            />

            {/* Text */}
            <div className="text-left md:w-1/2">
              <h3 className="text-3xl font-semibold text-[#35AC9F] mb-4">{service.title}</h3>
              <p className="text-lg leading-relaxed">{service.description}</p>
            </div>
          </div>
        ))}
      </section>

      <Footer />
    </div>
  );
}

export default Home;
