import { Link } from 'react-router-dom';
import React from 'react';
import WhoWeAreImage from '../Assets/Main Images/first_one.jpg'; // Replace with the correct path

function AboutPage() {
  return (
    <div className="container mx-auto px-4 py-12">
      {/* Who We Are Section */}
      <section className="flex flex-col md:flex-row items-center py-12 bg-gray-50 rounded-lg">
        <div className="md:w-1/2">
          <h2 className="text-4xl text-center font-semibold text-[#35AC9F]" style={{ fontFamily: "'Poppins', sans-serif" }}>
            Who We Are?
          </h2>
          <p className="text-lg text-gray-700 mt-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
            Pecific Coast Solutions Contracting Company (L.L.C) is a leader in construction, industrial support, and rental services in the Kingdom of Saudi Arabia. With a focus on safety, quality, and client satisfaction, we aim to be your preferred choice.
          </p>
        </div>
        <div className="md:w-1/2 mt-6 md:mt-0">
          <img src={WhoWeAreImage} alt="Who We Are" className="rounded-lg shadow-lg" />
        </div>
      </section>
    </div>
  );
}

export default AboutPage;
