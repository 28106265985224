// src/pages/Home.js
import React from 'react';
import Nav from '../Nav/Nav';
import Hero from './content/Hero';
import Contact from './content/contact';
import ContactForm from '../Contact/Contact';
import Footer from '../Footer/Footer';

function Home() {
  return (
    <div>
      <Nav />
      <Hero />
      <Contact />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Home;
