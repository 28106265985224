// src/pages/Home.js
import React from 'react';
import Nav from '../Nav/Nav';
import OurMission from './content/Our Mission';
import Footer from '../Footer/Footer';

function Home() {
  return (
    <div>
      <Nav />
      <OurMission />
      <Footer />
    </div>
  );
}

export default Home;
