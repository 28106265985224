// src/pages/Home.js
import React from 'react';
import Nav from '../Nav/Nav';
import Hero from '../Hero/Hero';
import Who from '../Who We Are/Who We Are';
import Choose from '../Why Chose Us/Why Choose Us';
import Service from '../Services/Service';
import Policy from '../Policy/Policy';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';

function Home() {
  return (
    <div>
      <Nav />
      <Hero />
      <Who />
      <Choose />
      <Service />
      <Policy />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
