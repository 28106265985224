// src/pages/content-consulting.js

import consultingImg1 from '../../Assets/Main Images/steptodown.com821587-1024x683.jpg'; // Update with actual image path
import consultingImg2 from '../../Assets/Main Images/steptodown.com347081-1024x540.jpg'; // Update with actual image path
import consultingImg3 from '../../Assets/Main Images/steptodown.com399419-1024x540.jpg'; // Update with actual image path

const content_consulting = [
  {
    img: consultingImg1, // Imported image
    title: 'Construction Consultancy Services',
    description:
      'Green Dots Contracting Company excels in providing Construction Consultancy services, offering expert guidance and support to clients in the Kingdom of Saudi Arabia. With a focus on delivering comprehensive construction solutions, our consultancy services cover project management, construction planning, and engineering expertise, ensuring the successful execution of construction projects.',
  },
  {
    img: consultingImg2, // Imported image
    title: 'Engineering Services',
    description:
      'Green Dots Contracting Company stands as a leader in providing comprehensive Engineering Services, offering specialized solutions to clients in the Kingdom of Saudi Arabia. With a focus on innovation, precision, and client satisfaction, our engineering expertise spans structural, piping, HVAC, and plumbing installations, ensuring the seamless development of diverse construction projects.',
  },
  {
    img: consultingImg3, // Imported image
    title: 'Project Feasibility Studies',
    description:
      'Green Dots Contracting Company specializes in providing Project Feasibility Studies, offering comprehensive assessments to assist clients in the Kingdom of Saudi Arabia in making informed decisions about the viability and success of their construction projects. With a focus on detailed analysis, risk assessment, and strategic planning, our feasibility studies provide clients with valuable insights to guide their investment decisions.',
  },
];

export default content_consulting;
