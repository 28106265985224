import React from 'react';

const Contact = () => {
  return (
    <div className="bg-white text-gray-800">

      {/* Contact Info Section */}
      <section className="md:px-16 lg:px-32 text-center">
        <h2 className="text-4xl font-bold text-[#35AC9F] mb-6">Contact Us</h2>
        <p className="text-lg mb-8">We would love to hear from you!</p>
        <div className="flex flex-col items-center">
          <div className="mb-4">
            <h3 className="text-xl font-semibold">Address:</h3>
            <p>Al Jabal Rd, Jubail City Center, Al Jubail 35514, Saudi Arabia</p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl font-semibold">Phone:</h3>
            <p>+966 12 345 6789</p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl font-semibold">Email:</h3>
            <p>info@pcs-saudi.com</p>
          </div>
        </div>
      </section>

      {/* Google Maps Section */}
      <section className="px-8 py-16 md:px-16 lg:px-32">
        <h2 className="text-4xl font-bold text-[#A97C50] mb-6 text-center">Our Location</h2>
        <div className="relative w-full h-[400px] rounded-lg overflow-hidden">
          <iframe
            title="Google Maps Location"
            className="absolute inset-0 w-full h-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3352.828073926973!2d49.61314951501957!3d27.00004065869552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49c25633a53ad9%3A0x38ec8d5377ee908c!2sAl%20Jabal%20Rd%2C%20Jubail%20City%20Center%2C%20Al%20Jubail%2035514%2C%20Saudi%20Arabia!5e0!3m2!1sen!2sus!4v1691234567890!5m2!1sen!2sus"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </section>

      
    </div>
  );
};

export default Contact;
