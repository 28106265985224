import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import this for navigation
import './Service.css'; // Custom CSS for animations and styles

// Sample image imports (replace with actual paths)
import ImageOne1 from '../Assets/Main Images/first_one.jpg';
import ImageOne2 from '../Assets/Main Images/skill2.jpg';
import ImageOne3 from '../Assets/Main Images/skill3.jpg';
import SecondImg1 from '../Assets/Main Images/general1.jpg';
import SecondImg2 from '../Assets/Main Images/general2.jpg';
import SecondImg3 from '../Assets/Main Images/general3.jpg';
import ThirdImg1 from '../Assets/Main Images/scrape1.jpg';
import ThirdImg2 from '../Assets/Main Images/scrape2.jpg';
import forth1 from '../Assets/Main Images/heavy2.jpg';
import forth2 from '../Assets/Main Images/Footer.jpg';
import forth3 from '../Assets/Main Images/largeslide.jpg';

const imagesServiceOne = [ImageOne1, ImageOne2, ImageOne3];
const imagesServiceTwo = [SecondImg1, SecondImg2, SecondImg3];
const imagesServiceThree = [ThirdImg1, ThirdImg2];
const imagesServiceFour = [forth1, forth2, forth3];

const ServiceBox = ({ images, title, description }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate(); // Navigation hook

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Change every 10 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  // Function to handle "Read More" button click
  const handleReadMore = () => {
    navigate('/services'); // Navigate to the services page
  };

  return (
    <div className="service-box border border-[#A97C50] rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl">
      <img src={images[currentImageIndex]} alt={title} className="w-full h-40 object-cover" />
      <div className="p-6 text-center">
        <h3 className="text-xl font-semibold mb-2 text-[#1D9E8F]" style={{ fontFamily: "'Poppins', sans-serif" }}>
          {title}
        </h3>
        <div className="w-16 h-1 bg-[#A97C50] mx-auto mb-4"></div>
        <p className="text-gray-600 mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
          {description}
        </p>
        {/* Read More Button */}
        <button
          onClick={handleReadMore}
          className="bg-gradient-to-r from-[#35AC9F] to-[#A97C50] text-white font-bold py-2 px-8 rounded-full text-lg shadow-lg hover:shadow-xl transition-all transform hover:scale-110 mt-4"
        >
          Read More
        </button>
      </div>
    </div>
  );
};

const OurServices = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        {/* Heading */}
        <h2 className="text-4xl font-bold text-center text-[#35AC9F] mb-8" style={{ fontFamily: "'Poppins', sans-serif" }}>
          Our Services
        </h2>

        {/* Service Boxes */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ServiceBox 
            images={imagesServiceOne} 
            title="Skilled Unskilled Man Power" 
            description="Providing qualified, experienced manpower including skilled workers and staff."
          />
          <ServiceBox 
            images={imagesServiceTwo} 
            title="General Contracting & Trading" 
            description="Dedicated to ensuring quality and reliability in every aspect of our projects, we provide hand and power tools and other industrial material."
          />
          <ServiceBox 
            images={imagesServiceThree} 
            title="Scrap Handling" 
            description="Innovating with cutting-edge technology solutions."
          />
          <ServiceBox 
            images={imagesServiceFour} 
            title="Industrial Heavy Equipment and Tools" 
            description="We provide all types of industrial heavy equipment, ensuring reliability and unrestricted operations."
          />
        </div>
      </div>
    </section>
  );
};

export default OurServices;
