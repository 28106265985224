// Projects.js

import React from 'react';
import ProjectCard from './ProjectCard';
import projectData from './ProjectData';

const Projects = () => {
  return (
    <div className="projects-section bg-gray-100 py-16 px-8">
      <h2 className="text-4xl font-bold text-center text-[#A97C50] mb-12">Our Projects</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {projectData.map((project, index) => (
          <ProjectCard 
            key={index}
            title={project.title}
            summary={project.summary}
            details={project.details}
            images={project.images}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
