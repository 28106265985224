import React from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';

const ContactPage = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ppwwr0l', 'template_qikqfih', e.target, 'K-XQsde56yaDVq0hQ')
      .then((result) => {
        alert('Message sent successfully!');
        console.log(result.text);
      }, (error) => {
        alert('Failed to send message. Please try again.');
        console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <section className="contact-page bg-white py-16 px-6 overflow-hidden">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 animate-fadeIn">
        {/* Left Side - Heading and Contact Info */}
        <div className="contact-info space-y-6">
          <h2 className="text-4xl font-bold text-[#35AC9F] mb-4 animate-slideInLeft" style={{ fontFamily: "'Poppins', sans-serif" }}>
            Get in Touch
          </h2>
          <p className="text-gray-600 text-lg animate-slideInLeft" style={{ fontFamily: "'Poppins', sans-serif" }}>
            Reach out to us with any inquiries or requests. We're here to help and respond promptly.
          </p>
          <div className="contact-box bg-[#A97C50] text-white p-6 mb-4 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl animate-fadeIn">
            <h3 className="text-xl font-semibold mb-2">Contact Number</h3>
            <p className="text-lg">+1 (123) 456-7890</p>
          </div>
          <div className="contact-box bg-[#1D9E8F] text-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl animate-fadeIn delay-200">
            <h3 className="text-xl font-semibold mb-2">Email</h3>
            <p className="text-lg">info@pcs-saudi.com</p>
          </div>
        </div>

        {/* Right Side - Contact Form */}
        <div className="contact-form bg-[#F9F9F9] p-8 rounded-lg shadow-lg transform transition duration-500 hover:shadow-2xl animate-slideInRight">
          <form onSubmit={sendEmail}>
            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2" style={{ fontFamily: "'Poppins', sans-serif" }}>Name</label>
              <input
                type="text"
                name="name"
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1D9E8F] transition duration-200 hover:bg-gray-100"
                placeholder="Your Name"
                required
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2">Email</label>
              <input
                type="email"
                name="email"
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1D9E8F] transition duration-200 hover:bg-gray-100"
                placeholder="Your Email"
                required
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2">Message</label>
              <textarea
                name="message"
                rows="4"
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1D9E8F] transition duration-200 hover:bg-gray-100"
                placeholder="Your Message"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="bg-[#35AC9F] text-white font-bold py-2 px-6 rounded-lg shadow-lg hover:bg-[#1D9E8F] transform transition duration-300 hover:scale-105 hover:shadow-2xl animate-bounceIn"
              style={{ fontFamily: "'Poppins', sans-serif" }}
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
