import React from 'react';
import Nav from '../Nav/Nav'; // Adjust the import paths based on your structure
import Hero from './Hero'; // Assuming Hero is already built and imported from this location
import Content from './content';
import Footer from '../Footer/Footer'; // Adjust the import path if needed

function Projects() {
  return (
    <div>
      <Nav />
      <Hero />
      <Content />
      <Footer />
    </div>
  );
}

export default Projects;
