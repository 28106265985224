import React, { useState, useEffect } from 'react';
import Nav from '../../Nav/Nav';
import Hero from '../What We Do/Hero';
import content_services from './content-services';
import Footer from '../../Footer/Footer';

function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Create a state to handle image rotation for each service
  const [imageIndexes, setImageIndexes] = useState(
    new Array(content_services.length).fill(0) // Initializes all indexes to 0
  );

  useEffect(() => {
    // Update image every 10 seconds for each service
    const interval = setInterval(() => {
      setImageIndexes((prevIndexes) =>
        prevIndexes.map(
          (index, i) => (i === 0 ? (index + 1) % content_services[i].images.length : index) // Rotate only for the first service (General Contracting Services)
        )
      );
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // State to handle the "Read More" button toggle
  const [isVisible, setIsVisible] = useState(false);

  // Function to toggle hidden content
  const toggleContent = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <Nav />
      <Hero />

      {/* Services Section */}
      <section className="services bg-gray-100 px-8 py-16 text-gray-800">
        <h2 className="text-4xl font-bold text-center text-[#A97C50] mb-12">What We Do</h2>

        {/* Loop through services */}
        {content_services.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center mb-16 ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`} // Alternate the layout based on index
          >
            {/* Image for each service with rotation */}
            <img
              src={service.images[imageIndexes[index]]} // Rotate image based on the service index
              alt={service.title}
              className="w-full md:w-1/2 rounded-lg mb-6 md:mb-0 md:mr-6 shadow-lg"
            />

            {/* Text */}
            <div className="text-left md:w-1/2">
              <h3 className="text-3xl font-semibold text-[#35AC9F] mb-4">{service.title}</h3>
              <p className="text-lg leading-relaxed">{service.description}</p>

              {/* Read More Button for General Contracting Services */}
              {index === 0 && (
                <button
                  onClick={toggleContent}
                  className="mt-4 text-[#A97C50] font-bold text-lg"
                >
                  {isVisible ? 'Show Less' : 'Read More'}
                </button>
              )}

              {/* Hidden Content for General Contracting Services */}
              {isVisible && index === 0 && (
                <div className="mt-4">
                  <h4 className="text-xl text-[#35AC9F] font-semibold">Civil Services</h4>
                  <ul>
                    <li>• Excavation and Backfilling</li>
                    <li>• Building Construction</li>
                    <li>• Civil Finishing works (Plaster, Painting, Tiles Fixing)</li>
                  </ul>
                  <h4 className="text-xl text-[#35AC9F] font-semibold mt-4">Electrical Service</h4>
                  <ul>
                    <li>• Cable Tray Installation</li>
                    <li>• Cable Pulling/Laying</li>
                    <li>• Electrical Equipment Installation</li>
                    <li>• Electrical Wiring</li>
                  </ul>
                  <h4 className="text-xl text-[#35AC9F] font-semibold mt-4">Mechanical Services</h4>
                  <ul>
                    <li>• Rigging / Load Lifting</li>
                    <li>• Fabrication</li>
                    <li>• Welding</li>
                    <li>• Fence Installation</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </section>

      <Footer />
    </div>
  );
}

export default Home;
