import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/Logo/Pacific coast solution-01.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = (menu) => {
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setHoveredMenu('');
  };

  return (
    <nav className="fixed z-20 w-full bg-[#F9F9F9] bg-opacity-25 shadow-lg">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-32 md:h-32">
          {/* Logo */}
          <div className="flex items-center flex-shrink-0">
            <Link to="/">
              <img className="w-24 h-auto md:w-32 lg:w-40 xl:w-48" src={Logo} alt="Logo" />
            </Link>
          </div>

          {/* Hamburger Icon */}
          <button onClick={toggleMenu} className="text-transparent md:hidden">
            &#9776;
          </button>

          {/* Desktop Navigation Links */}
          <div className={`flex items-center space-x-4 md:flex ${isOpen ? 'hidden' : 'block'}`}>
            <Link to="/" className="px-5 py-4 text-[#A97C50] hover:text-teal-400">Home</Link>

            {/* Who Are We with Sub-menu */}
            <div
              className="relative group"
              onMouseEnter={() => handleMouseEnter('whoAreWe')}
              onMouseLeave={handleMouseLeave}
            >
              <button className="px-5 py-4 text-[#A97C50] hover:text-teal-400">
                Who Are We
              </button>
              {hoveredMenu === 'whoAreWe' && (
                <div className="absolute left-0 w-48 bg-transparent shadow-lg">
                  <Link to="/about-us" className="block text-md px-4 py-2 text-[#A97C59] hover:text-[#35AC9F]">About Us</Link>
                  <Link to="/our-mission" className="block text-md px-4 py-2 text-[#A97C50] hover:text-[#35AC9F]">Our Mission</Link>
                </div>
              )}
            </div>

            {/* What We Do with Sub-menu */}
            <div
              className="relative group"
              onMouseEnter={() => handleMouseEnter('whatWeDo')}
              onMouseLeave={handleMouseLeave}
            >
              <button className="px-5 py-4 text-[#A97C50] hover:text-teal-400">
                What We Do
              </button>
              {hoveredMenu === 'whatWeDo' && (
                <div className="absolute left-0 w-48 bg-transparent shadow-lg">
                  <Link to="/services" className="block text-md px-4 py-2 text-[#A97C50] hover:text-[#35AC9F]">Services</Link>
                </div>
              )}
            </div>

            {/* Projects with Sub-menu */}
            <div
              className="relative group"
              onMouseEnter={() => handleMouseEnter('projects')}
              onMouseLeave={handleMouseLeave}
            >
              <button className="px-5 py-4 text-[#A97C50] hover:text-teal-400">
                Projects
              </button>
              {hoveredMenu === 'projects' && (
                <div className="absolute left-0 w-48 bg-transparent shadow-lg">
                  <Link to="/projects" className="block text-md px-4 py-2 text-[#A97C50] hover:text-[#35AC9F]">Projects</Link>
                </div>
              )}
            </div>
            <Link to="/contact" className="px-5 py-4 text-[#A97C50] hover:text-teal-400">Contact</Link>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="bg-transparent border-t border-gray-200 md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="/" className="block px-3 py-2 text-base font-medium text-[#A97C50] rounded-md hover:text-[#35AC9F]">Home</Link>
            {/* Who Are We - Mobile Sub-menu */}
            <div>
              <button
                onMouseEnter={() => handleMouseEnter('whoAreWe')}
                onMouseLeave={handleMouseLeave}
                className="block w-full px-3 py-2 text-base font-medium text-left text-[#A97C50] rounded-md hover:text-[#35AC9F]"
              >
                Who Are We
              </button>
              {hoveredMenu === 'whoAreWe' && (
                <div className="ml-4">
                  <Link to="/about-us" className="block px-3 py-2 text-sm text-[#A97C50] hover:text-[#35AC9F]">About Us</Link>
                  <Link to="/our-mission" className="block px-3 py-2 text-sm text-[#A97C50] hover:text-[#35AC9F]">Our Mission</Link>
                </div>
              )}
            </div>
            {/* What We Do - Mobile Sub-menu */}
            <div>
              <button
                onMouseEnter={() => handleMouseEnter('whatWeDo')}
                onMouseLeave={handleMouseLeave}
                className="block w-full px-3 py-2 text-base font-medium text-left text-[#A97C50] rounded-md hover:text-[#35AC9F]"
              >
                What We Do
              </button>
              {hoveredMenu === 'whatWeDo' && (
                <div className="ml-4">
                  <Link to="/services" className="block px-3 py-2 text-sm text-[#A97C50] hover:text-[#35AC9F]">Services</Link>
                </div>
              )}
            </div>
            {/* Projects - Mobile Sub-menu */}
            <div>
              <button
                onMouseEnter={() => handleMouseEnter('projects')}
                onMouseLeave={handleMouseLeave}
                className="block w-full px-3 py-2 text-base font-medium text-left text-[#A97C50] rounded-md hover:text-[#35AC9F]"
              >
                Projects
              </button>
              {hoveredMenu === 'projects' && (
                <div className="ml-4">
                  <Link to="/projects" className="block px-3 py-2 text-sm text-[#A97C50] hover:text-[#35AC9F]">Projects</Link>
                </div>
              )}
            </div>
            <Link to="/careers" className="block px-3 py-2 text-base font-medium text-[#A97C50] rounded-md hover:text-[#35AC9F]">Careers</Link>
            <Link to="/partners" className="block px-3 py-2 text-base font-medium text-[#A97C50] rounded-md hover:text-[#35AC9F]">Partners</Link>
            <Link to="/contact" className="block px-3 py-2 text-base font-medium text-[#A97C50] rounded-md hover:text-[#35AC9F]">Contact</Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
