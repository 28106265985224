// Footer.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faPeopleGroup, faWrench, faUserGraduate } from '@fortawesome/free-solid-svg-icons'; // Add other icons as appropriate
import backgroundImage from '../Assets/Main Images/Footer.jpg'; // Background image for the footer
import './Footer.css';

const footerLinks = [
  {
    title: "Who we are",
    links: [
      { label: "About WNR", icon: faBuilding },
      { label: "Our Leadership", icon: faPeopleGroup },
      { label: "Our HSE", icon: faWrench },
      { label: "Certificates and Memberships", icon: faBuilding },
    ],
  },
  {
    title: "What we do",
    links: [
      { label: "Construction Services", icon: faBuilding },
      { label: "Engineering Services", icon: faWrench },
      { label: "Manpower & Equipment Supply", icon: faPeopleGroup },
    ],
  },
  {
    title: "Our Partners",
    links: [
      { label: "Ster Group - Ster Construction", icon: faBuilding },
      { label: "IPICC Company", icon: faBuilding },
      { label: "QMOF Company", icon: faBuilding },
    ],
  },
  {
    title: "Work with us",
    links: [
      { label: "Professionals", icon: faUserGraduate },
      { label: "Recent Graduates & Students", icon: faUserGraduate },
      { label: "Skilled Craft", icon: faWrench },
    ],
  },
];

const Footer = () => {
  return (
    <footer
      className="relative bg-cover bg-center text-white h-screen py-12"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dark overlay */}

      <div className="relative container mx-auto text-center mb-12 z-10">
        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
          - Since 2021 -
        </h2>
        <p className="text-xl md:text-2xl font-semibold text-[#A97C50] mb-8" style={{ fontFamily: "'Poppins', sans-serif" }}>
          Need Construction or Machinary Support?
        </p>
      </div>

      <div className="relative container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-6 z-10">
        {footerLinks.map((section, index) => (
          <div key={index} className="space-y-4">
            <h3 className="text-lg md:text-xl font-bold text-[#1D9E8F] mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
              {section.title}
            </h3>
            <ul className="space-y-2">
              {section.links.map((link, idx) => (
                <li
                  key={idx}
                  className="flex items-center text-sm sm:text-base space-x-2 transition transform hover:scale-105"
                >
                  <FontAwesomeIcon icon={link.icon} className="text-[#A97C50]" />
                  <span>{link.label}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
