// projectData.js

import projImage1_1 from '../Assets/Main Images/water1.jpg';
import projImage1_2 from '../Assets/Main Images/water2.jpg';
import projImage1_3 from '../Assets/Main Images/water3.jpg';
import projImage2 from '../Assets/Main Images/water2.jpg';

const projectData = [
  {
    title: 'Water Transmission',
    summary: 'Jubail Riyadh Water Transmission System (JRWTS) PKG-C',
    details: 'This project focuses on the construction and management of the Jubail Riyadh Water Transmission System (JRWTS) PKG-C. The system is designed to efficiently transport water over long distances, ensuring a reliable water supply for the region. Key phases include pipeline construction, system testing, and integration with existing infrastructure to achieve optimal performance and sustainability.',
    images: [projImage1_1, projImage1_2, projImage1_3],
  },
  {
    title: 'Project Two',
    summary: 'A brief overview of Project Two.',
    details: 'Detailed information about Project Two including goals, execution, and outcomes.',
    images: [projImage2],
  },
  // Add more projects as needed
];

export default projectData;
